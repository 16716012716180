#map-box {
  margin: 5px;
}

#house-fieldset {
  padding: 0px;
  margin: 0 0 0 5px;
  // border: 1px solid lightgray;
}
#house-header {
    font-size: large;
    margin-top: 2px;
    //padding: 0px;
    //margin: 0px;
    // border: 1px solid lightgray;
  }
  #house-label {
    font-size: medium;
    padding: 0px;
    margin: 5px;
    //border: 1px solid lightgray;
  }