.back-button {
    padding: 0;
    cursor: pointer;
    margin-right: 10px;
}

.horizontal {
    margin: 0;
    padding: 0;
}

.horizontal li {
    display: inline-block;
    //border: 1px solid #000;
}